body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
/* *{
  font-family: 'Times New Roman', Times, serif;
} */
.ant-modal-root,
.ant-modal-content {
  font-family: var(--dynamic-font-family), sans-serif;
}
.ant-tooltip,
.ant-tooltip-inner {
  font-family: var(--dynamic-font-family), sans-serif;
}
